import React from "react";
import { Link } from "gatsby";
import Menu from "./Menu";
import Hamburger from "./Hamburger";
import logo from "../images/logo.svg";
import logoMobile from "../images/logo-mobile.png";
import MenuMobile from "./MenuMobile";
import SvgIconsSet from "./SvgIconsSet";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    };
  }

  toggleMenu = (menuActive) => {
    this.setState((prevState) => ({
      menuActive: !prevState.menuActive,
    }));
  };

  render() {
    return (
      <div className="header" id="header">
        <div className="container wrap-nav">
          <div className="logo">
            <Link to="/">
              <img alt="iTelaSoft Logo" src={logo} />
            </Link>
          </div>
          <div className="logo-mobile">
            <Link to="/">
              <img alt="iTelaSoft Logo" src={logoMobile} />
            </Link>
          </div>
          <MenuMobile active={this.state.menuActive} />
          <Menu />
          <div className="contact-wrap">
            <Link
              className="btn-contact btn-m-active btn-primary btn-icon-button"
              activeClassName="active"
              to="/contact/"
            >
              <span className="btn-text all-pgs">CONTACT US</span>
              <span className="btn-text marketing-pg">SCHEDULE A DISCUSSION</span>
              <span className="btn-icon">
                <SvgIconsSet.SideArrow />
              </span>
            </Link>
          </div>
          
          <Hamburger toggleMenu={this.toggleMenu} />
        </div>
      </div>
    );
  }
}

export default Header;
