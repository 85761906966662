import React from "react";
import { Link } from "gatsby";
import SvgIconsSet from "./SvgIconsSet";
import iconPoc from "../images/service-icons/serviceicon-poc.svg";
import iconUx from "../images/service-icons/serviceicon-ux.svg";
import iconProduct from "../images/service-icons/serviceicon-product.svg";
import iconCompliance from "../images/service-icons/serviceicon-compliance.svg";
import iconDigital from "../images/service-icons/serviceicon-digital.svg";
import iconData from "../images/service-icons/serviceicon-data.svg";
import iconSoftware from "../images/service-icons/serviceicon-software.svg";
import iconResources from "../images/service-icons/serviceicon-resources.svg";
import iconSupport from "../images/service-icons/serviceicon-support.svg";
import iconTesting from "../images/service-icons/serviceicon-testing.svg";
import iconCloud from "../images/service-icons/serviceicon-cloud.svg";
class MenuMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submenuActive: false,
    };
  }

  handleToggleSubmenu = () => {
    this.setState((prevState) => ({
      submenuActive: !prevState.submenuActive,
    }));
  };

  render() {
    return (
      <div
        id="main-menu-mobile"
        className={`main-menu-mobile ${this.props.active ? "open" : ""}`}
      >
        <ul>
          <li className={`nav-item ${this.state.submenuActive ? "open" : ""}`}>
            {/* sub menu  */}
            <React.Fragment>
              <a
                onClick={this.handleToggleSubmenu}
                aria-label="menu toggle"
                className={`toggle__submenu ${
                  this.state.submenuActive ? "open" : ""
                }`}
              >
                What We Do
                <span className="down">
                  <SvgIconsSet.DownArrow />
                </span>
                <span className="up">
                  <SvgIconsSet.UpArrow />
                </span>
              </a>
              <ul
                className={`main-menu-mobile__submenu ${
                  this.state.submenuActive ? "open" : ""
                }`}
              >
                <div className="service-category see-overview">
                  {/* <p>We are put into this world to engage and excite your customers with innovative digital user experiences</p> */}

                  <Link tag="a" href="/services/">
                    {" "}
                    <h4>See Services overview</h4>
                    <SvgIconsSet.SideArrow />
                  </Link>
                  <Link tag="a" href="/services/build-engineer/fintech" className="mt-2">
                    {" "}
                    <h4>See Fintech Services</h4>
                    <SvgIconsSet.SideArrow />
                  </Link>
                </div>
                <div className="service-category">
                  <h4>Strategy &amp; Design</h4>
                  <Link tag="a" to="/services/strategy-design/poc-mvp">
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={''}
                    /> */}
                    <div className="menu-item-icon icon1"></div>
                    <h5>Prototype and design from PoC to MVP</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/strategy-design/ux"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconUx}
                    /> */}
                    <div className="menu-item-icon icon2"></div>
                    <h5>UI &amp; UX Design</h5>
                  </Link>
                </div>

                <div className="service-category">
                  <h4>Build &amp; Engineer</h4>
                  <Link
                    tag="a"
                    to="/services/build-engineer/product-development"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconProduct}
                    /> */}
                    <div className="menu-item-icon icon3"></div>
                    <h5>Product Development</h5>
                  </Link>
                  <Link tag="a" to="/services/build-engineer/ai">
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconData}
                    /> */}
                    <div className="menu-item-icon icon4"></div>
                    <h5>Data &amp; AI Enablement</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/build-engineer/digital-transformation"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconDigital}
                    /> */}
                    <div className="menu-item-icon icon5"></div>
                    <h5>Digital Transformation</h5>
                  </Link>
                  {/*<Link
                    tag="a"
                    to="/services/build-engineer/compliance-services"
                  >
                     <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconCompliance}
                    /> 
                    <div className="menu-item-icon icon7"></div> 
                    <h5>Compliance Services</h5>
                  </Link>*/}
                  <Link
                    tag="a"
                    to="/services/build-engineer/software-development"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconSoftware}
                    /> */}
                    <div className="menu-item-icon icon8"></div>
                    <h5>Customised Software Development</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/build-engineer/quality-assurance"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconTesting}
                    /> */}
                    <div className="menu-item-icon icon9"></div>
                    <h5>Independent Validation & Quality Assurance</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/build-engineer/salesforce"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconCloud}
                    /> */}
                    <div className="menu-item-icon icon6"></div>
                    <h5>Salesforce Development Services</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/build-engineer/wso2-services"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconCloud}
                    /> */}
                    <div className="menu-item-icon icon12"></div>
                    <h5>WSO2 Services</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/build-engineer/aws"
                  >
                    <div className="menu-item-icon icon14"></div>
                    <h5>AWS Solutions</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/build-engineer/devops"
                  >
                    <div className="menu-item-icon icon15"></div>
                    <h5>DevOps Services</h5>
                  </Link>
                </div>

                <div className="service-category">
                  <h4>Managed Services &amp; Resources</h4>
                  <Link
                    tag="a"
                    to="/services/managed-services-resources/support-maintenance"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconSupport}
                    /> */}
                    <div className="menu-item-icon icon10"></div>
                    <h5>Support &amp; Maintenance</h5>
                  </Link>
                  <Link
                    tag="a"
                    to="/services/managed-services-resources/resources"
                  >
                    {/* <img
                      alt="Service Icon"
                      className="menu-item-icon"
                      src={iconResources}
                    /> */}
                    <div className="menu-item-icon icon11"></div>
                    <h5>Certified Professionals</h5>
                  </Link>
                </div>
              </ul>
            </React.Fragment>
          </li>
          {/* <li>
            <Link to="/products/xg-automation-platform/">Products</Link>
          </li> */}
          {/* <li className="nav-item">
            <Link to="/clients/">Clients</Link>
          </li> */}
          <li className="nav-item">
            <Link to="/partners/">Partners</Link>
          </li>
          <li className="nav-item">
            <Link to="/about/">About Us</Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/testimonials/">Testimonials</Link>
          </li>*/}
          <li className="nav-item">
            <Link to="/blog/">Blog</Link>
          </li>
          <li className="nav-item">
            <Link to="/careers/">Careers</Link>
          </li>
          <li className="nav-item">
            <Link to="/noteworthy/">News</Link>
          </li>
          <li className="nav-item">
          <Link
            className="btn-contact btn-m-active btn-primary btn-icon-button"
            activeClassName="active"
            to="/contact/"
          >
            <span className="btn-text">CONTACT US</span>
            <span className="btn-icon">
              <SvgIconsSet.SideArrow />
            </span>
          </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default MenuMobile;
