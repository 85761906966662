import React from "react";
import FooterLinks from "./FooterLinks"; // Assuming your FooterLinks component is in a separate file
import SvgIconsSet from "./SvgIconsSet";

const services = [
  {
    id: 1,
    hrefLink: "/services/",
    linkTitle: "Services",
  },
];

const partners = [
  {
    id: 1,
    hrefLink: "/partners/",
    linkTitle: "Partners",
  },
];

const news = [
  {
    id: 1,
    hrefLink: "/noteworthy/",
    linkTitle: "News",
  },
];

const about = [
  {
    id: 1,
    hrefLink: "/about/",
    linkTitle: "About Us",
  },
];

const careers = [
  {
    id: 1,
    hrefLink: "/careers/",
    linkTitle: "Careers",
  },
];

const contact = [
  {
    id: 1,
    hrefLink: "/contact/",
    linkTitle: "Contact Us",
  },
];

const d = new Date();
let currentYear = d.getFullYear();

const Footer = () => {
  return (
    <div className="footer-wrap">
      <div className="footer-top">
        <div className="section-infor-wrap">
          <div>
            <div className="footer-logo mb-1">
              <a href="/">
                <SvgIconsSet.LogoOrginal />
              </a>
            </div>
            {/* <div className="footer-description">
              Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam
              mauris sed ma
            </div> */}
          </div>
          <div className="icon-social-media">
            <a
              href="https://www.facebook.com/iTelaSoft/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgIconsSet.GrayFaceBook />
            </a>
            <a
              href="https://twitter.com/itelasoft"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgIconsSet.GrayTwitter />
            </a>
            {/* <a href="/" target="_blank" rel="noopener noreferrer">
              <SvgIconsSet.GrayInsta />
            </a> */}
            <a
              href="https://www.linkedin.com/company/itelasoft-pvt-limited/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgIconsSet.GrayLinkdin />
            </a>
            <a
              href="https://www.youtube.com/channel/UCEBSHvKia8sRxDu8RbgWOqA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SvgIconsSet.GrayYoutube />
            </a>
          </div>
        </div>
        <div className="footer-menu-wrap">
          <div className="footer-menu">
            <FooterLinks menuTitle={"About Us"} links={services} />
            <FooterLinks menuTitle={"Services"} links={partners} />
            <FooterLinks menuTitle={"Clients"} links={about} />
            <FooterLinks menuTitle={"Clients"} links={news} />
            <FooterLinks menuTitle={"Clients"} links={careers} />
            <FooterLinks menuTitle={"Clients"} links={contact} />
          </div>
          <div className="footer-bottom">
            <span className="footer-copyright">
              © 2009 – {currentYear} iTelaSoft (Pty) Ltd. All Rights Reserved
            </span>{" "}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        {/* <span className="footer-copyright">
          © 2009 – {currentYear} iTelaSoft (Pty) Ltd. All Rights Reserved
        </span>{" "} */}
        {/* <a href="" target="_blank">
          Terms and Conditions
        </a>{" "}
        <span className="footer-copyright">| </span>
        <a href="" target="_blank">
          Privacy Policy
        </a> */}
      </div>
    </div>
  );
};

export default Footer;
