import React from "react";
import {Link} from 'gatsby';
import icon from "../images/icon.svg";
import iconPoc from "../images/service-icons/serviceicon-poc.svg";
import iconUx from "../images/service-icons/serviceicon-ux.svg";
import iconProduct from "../images/service-icons/serviceicon-product.svg";
import iconCompliance from "../images/service-icons/serviceicon-compliance.svg";
import iconDigital from "../images/service-icons/serviceicon-digital.svg";
import iconData from "../images/service-icons/serviceicon-data.svg";
import iconSoftware from "../images/service-icons/serviceicon-software.svg";
import iconResources from "../images/service-icons/serviceicon-resources.svg";
import iconSupport from "../images/service-icons/serviceicon-support.svg";
import iconTesting from "../images/service-icons/serviceicon-testing.svg";
import iconCloud from "../images/service-icons/serviceicon-cloud.svg";
import arrowRight from "../images/arrow-right.svg";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Row,
  Col
} from "reactstrap";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.state = {
      dropdownOpen: false,
    };
    this.state = {
      dropdownOpen2: false,
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  toggle2() {
    this.setState((prevState) => ({
      dropdownOpen2: !prevState.dropdownOpen2,
    }));
  }

  render() {
    return (
      <div id="main-menu" className="main-menu">
        <ul>
          <Dropdown
            className="about-us"
            nav
            isOpen={this.state.dropdownOpen}
            toggle={this.toggle}
          >
            <DropdownToggle nav caret>
              What We Do
            </DropdownToggle>
            <DropdownMenu className="mega-menu-dropdown">
              <div className="mega-menu-inner">
                <Container>
                  <Row>
                    <Col lg={3} className="mega-menu-overview">
                      <Row>
                        <h4>Services Overview</h4>
                        <p>We exist to solve business problems with innovative technological solutions</p>
                        <DropdownItem tag="a" href="/services/">
                          Learn More<span><img alt="arrow" src={arrowRight} /></span>
                        </DropdownItem>
                      </Row>
                      <Row className="mt-4">
                        <h4>Industry Verticals</h4>
                        <DropdownItem tag="a" href="/services/build-engineer/fintech">
                          Fintech Services<span><img alt="arrow" src={arrowRight} /></span>
                        </DropdownItem>
                      </Row>
                    </Col>
                    <Col lg={9} className="mega-menu-content">
                      <div className="service-category">
                        <h4>Strategy &amp; Design</h4>
                        <Row>
                          <Col lg={6}>
                            <DropdownItem tag="a" href="/services/strategy-design/poc-mvp">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconPoc} /> */}
                              <div className="menu-item-icon icon1"></div>
                              <div>
                                <h5>Prototype and design from PoC to MVP
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>We take you from ideation to delivery and beyond</div>
                              </div>
                              
                            </DropdownItem>
                          </Col>
                          <Col lg={6}>
                            <DropdownItem tag="a" href="/services/strategy-design/ux">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconUx} /> */}
                              <div className="menu-item-icon icon2"></div>
                              <div>
                                <h5>UI &amp; UX Design
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Our design experts know how to create for the now and the future</div>
                              </div>
                            </DropdownItem>
                          </Col>
                        </Row>
                        
                      </div>

                      <div className="service-category">
                        <h4>Build &amp; Engineer</h4>
                        <Row>
                          <Col lg={6}>
                            <DropdownItem tag="a" href="/services/build-engineer/product-development">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconProduct} /> */}
                              <div className="menu-item-icon icon3"></div>
                              <div>
                                <h5>Product Development
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>If you dream it, we can build it</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem tag="a" href="/services/build-engineer/ai">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconData} /> */}
                              <div className="menu-item-icon icon4"></div>
                              <div>
                                <h5>Data &amp; AI Enablement
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Unearth deep data insights with innovative AI solutions</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem tag="a" href="/services/build-engineer/digital-transformation">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconDigital} /> */}
                              <div className="menu-item-icon icon5"></div>
                              <div>
                                <h5>Digital Transformation
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Unlock new business opportunities with transformational technological solutions</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem tag="a" href="/services/build-engineer/salesforce">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconCloud} /> */}
                              <div className="menu-item-icon icon6"></div>
                              <div>
                                <h5>Salesforce Development Services
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Salesforce development, integration and app exchange services</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem tag="a" href="/services/build-engineer/devops">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconCloud} /> */}
                              <div className="menu-item-icon icon15"></div>
                              <div>
                                <h5>DevOps Services
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Innovate faster, deploy smarter with DevOps</div>
                              </div>
                            </DropdownItem>
                            {/* <DropdownItem tag="a" href="/services/build-engineer/fintech">
                              <img alt="Service Icon" className="menu-item-icon" src={iconTesting} />
                              <div className="menu-item-icon icon13"></div>
                              <div>
                                <h5>Fintech Startup/Scale up Services
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Your Trusted Tech Partner for Success</div>
                              </div>
                            </DropdownItem> */}
                          </Col>
                          <Col lg={6}>
                            {/*<DropdownItem tag="a" href="/services/build-engineer/compliance-services">
                              <img alt="Service Icon" className="menu-item-icon" src={iconCompliance} /> 
                              <div className="menu-item-icon icon7"></div>
                              <div>
                                <h5>Compliance Services
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Manage compliance obligations with confidence</div>
                              </div>
                            </DropdownItem>*/}
                            <DropdownItem tag="a" href="/services/build-engineer/software-development">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconSoftware} /> */}
                              <div className="menu-item-icon icon8"></div>
                              <div>
                                <h5>Customised Software Development
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Customise your solution to perfectly match your needs</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem tag="a" href="/services/build-engineer/quality-assurance">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconTesting} /> */}
                              <div className="menu-item-icon icon9"></div>
                              <div>
                                <h5>Independent Validation & Quality Assurance
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Ensure your product is fit for purpose and comply with user expectations</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem tag="a" href="/services/build-engineer/wso2-services">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconTesting} /> */}
                              <div className="menu-item-icon icon12"></div>
                              <div>
                                <h5>WSO2 Services
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>WSO2 implementation, CI/CD, analytics and support</div>
                              </div>
                            </DropdownItem>
                            <DropdownItem tag="a" href="/services/build-engineer/aws">
                              <div className="menu-item-icon icon14"></div>
                              <div>
                                <h5>AWS Solutions
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Unlocking the power of AWS for growth and optimisation</div>
                              </div>
                            </DropdownItem>
                          </Col>
                        </Row>
                        
                      </div>

                      <div className="service-category">
                        <h4>Managed Services &amp; Resources</h4>
                        <Row>
                          <Col lg={6}>
                            <DropdownItem tag="a" href="/services/managed-services-resources/support-maintenance">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconSupport} /> */}
                              <div className="menu-item-icon icon10"></div>
                              <div>
                                <h5>Support &amp; Maintenance
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Create capacity and efficiency in your business</div>
                              </div>
                              
                            </DropdownItem>
                          </Col>
                          <Col lg={6}>
                            <DropdownItem tag="a" href="/services/managed-services-resources/resources">
                              {/* <img alt="Service Icon" className="menu-item-icon" src={iconResources} /> */}
                              <div className="menu-item-icon icon11"></div>
                              <div>
                                <h5>Certified Professionals
                                <span><img alt="arrow" src={arrowRight} /></span>
                                </h5>
                                <div>Extend your team with our team</div>
                              </div>
                            </DropdownItem>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </DropdownMenu>
          </Dropdown>
          {/* <Dropdown
            className="products"
            nav
            isOpen={this.state.dropdownOpen2}
            toggle={this.toggle2}
          >
            <DropdownToggle nav caret>
              Products
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag="a" href="/products/xg-automation-platform/">
                XG Automation Platform
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
          {/* <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/products">
              Products
            </Link>
          </li> */}
          
          {/* <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/clients/">
              Clients
            </Link>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/partners/">
              Partners
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/about/">
              About Us
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/resources/">
              Resources
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/testimonials/">
              Testimonials
            </Link>
          </li> */}
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/blog/">
              Blog
            </Link>
          </li> 
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/careers/">
              Careers
            </Link>
          </li>
          
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/noteworthy/">
              News
            </Link>
          </li> 
        </ul>
      </div>
    );
  }
}

export default Menu;

// export default props => (
//   <StaticQuery
//     query={graphql`
//       query SiteTitleQuery {
//         site {
//           siteMetadata {
//             menuLinks {
//               name
//               link
//             }
//           }
//         }
//       }
//     `}
//     render={data => <Menu data={data} />}
//   />
// );
