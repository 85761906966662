import React from "react";

const FooterLinks = ({ links, menuTitle }) => {
  return (
    <div>
      {/* <div className="footer-menu-title">{menuTitle}</div> */}
      {links.map(({ id, hrefLink, linkTitle }) => (
        <div className="footer-menu-link" key={id}>
          <a href={hrefLink} target="_blank" rel="noopener noreferrer">
            {linkTitle}
          </a>
        </div>
      ))}
    </div>
  );
};

export default FooterLinks;
