import React from "react";

const SideArrow = ({ size = 20 }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.8653 1.19336L10.8999 5.9991L5.8653 10.8048"
      stroke="#222222"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8999 6L1.09998 6"
      stroke="#222222"
      stroke-width="1.4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const RightArrow = ({ size = 20 }) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.88428 2.38281L10.1996 6.50202L5.88428 10.6212"
      stroke=" "
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.1998 6.5L1.7998 6.5"
      stroke="none"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Linkedin = ({ size = 20 }) => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      fill="#0A66C2"
      d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 01-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 002 2.866v10.268a.88.88 0 00.885.866h10.226a.882.882 0 00.889-.866V2.865a.88.88 0 00-.889-.864z"
    />
  </svg>
);

const DownArrow = ({ size = 20 }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.01001 7.31911L10.51 13.2422L16.01 7.31911"
      stroke="#304659"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const UpArrow = ({ size = 20 }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.01001 13.2434L10.51 7.32031L16.01 13.2434"
      stroke="#304659"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const LogoOrginal = ({ size = 20 }) => (
  <svg
    width="131"
    height="25"
    viewBox="0 0 131 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M125.183 0.5254V3.79595H124.933V0.5254H123.882V0.304688H126.224V0.5254H125.183Z"
      fill="black"
    />
    <path
      d="M129.987 3.79258V1.30455C129.987 1.24436 129.997 1.07381 130.017 0.792901H130.007C129.957 0.923321 129.907 1.02365 129.877 1.09387L128.586 3.78254H128.506L127.215 1.1039C127.175 1.01361 127.134 0.903257 127.094 0.772836H127.084C127.094 0.933354 127.104 1.08384 127.104 1.23432V3.77251H126.854V0.291283H127.084L128.436 3.10034C128.446 3.12041 128.456 3.14047 128.466 3.17057C128.476 3.20067 128.486 3.22073 128.496 3.25083C128.516 3.29096 128.526 3.34112 128.546 3.39128H128.556L128.586 3.31102C128.586 3.30099 128.616 3.23076 128.676 3.08028L130.007 0.28125H130.227V3.77251H129.987V3.79258Z"
      fill="black"
    />
    <path
      d="M29.224 5.68945C28.9437 5.68945 28.6935 5.58912 28.4833 5.38848C28.2632 5.18783 28.1631 4.93702 28.1631 4.63605C28.1631 4.33508 28.2732 4.0943 28.4934 3.91372C28.7135 3.73314 28.9537 3.63281 29.234 3.63281C29.5242 3.63281 29.7744 3.7231 29.9946 3.90369C30.2148 4.08427 30.3249 4.32505 30.3249 4.63605C30.3249 4.92699 30.2148 5.16776 30.0046 5.37844C29.7844 5.57909 29.5242 5.68945 29.224 5.68945ZM28.5934 23.0153V9.17068H29.8845V23.0153H28.5934Z"
      fill="black"
    />
    <path
      d="M40.223 4.86679V23.0153H38.8519V4.86679H33.0471V3.63281H46.0378V4.86679H40.223Z"
      fill="black"
    />
    <path
      d="M44.9668 16.1417C44.9668 18.0479 45.4272 19.5327 46.338 20.6061C47.2487 21.6796 48.4997 22.2113 50.091 22.2113C51.7024 22.2113 53.2736 21.6395 54.8049 20.4958V21.82C53.3137 22.8333 51.6623 23.345 49.8208 23.345C47.9693 23.345 46.4681 22.7029 45.3271 21.4288C44.1862 20.1446 43.6157 18.3388 43.6157 16.0113C43.6157 13.9948 44.2162 12.2893 45.4072 10.9048C46.5982 9.52036 48.1494 8.82812 50.061 8.82812C51.8425 8.82812 53.2136 9.43007 54.1744 10.644C55.1252 11.8479 55.6056 13.5032 55.6056 15.5899V16.1417H44.9668ZM54.2545 15.0081C54.1944 13.4129 53.8041 12.1789 53.0635 11.2961C52.3229 10.4132 51.302 9.97181 50.001 9.97181C48.6499 9.97181 47.5389 10.4032 46.6582 11.276C45.7775 12.1488 45.227 13.3929 45.0269 15.0081H54.2545Z"
      fill="black"
    />
    <path d="M59.1586 23.0095V2.52344H60.4496V23.0095H59.1586Z" fill="black" />
    <path
      d="M73.6404 23.0117V20.2427H73.5803C73.12 21.1858 72.4594 21.9482 71.5787 22.51C70.708 23.0719 69.7372 23.3528 68.6663 23.3528C67.3052 23.3528 66.2243 22.9816 65.4236 22.2492C64.633 21.5068 64.2327 20.5738 64.2327 19.4502C64.2327 17.0324 65.894 15.5777 69.2168 15.0761L73.6304 14.444C73.6304 11.4744 72.4694 9.97963 70.1475 9.97963C68.5662 9.97963 67.025 10.5916 65.5337 11.8155V10.3107C66.0642 9.9094 66.7848 9.55827 67.7055 9.26733C68.6163 8.97639 69.477 8.83594 70.2776 8.83594C71.7588 8.83594 72.8998 9.27736 73.7104 10.1602C74.5211 11.0431 74.9214 12.3272 74.9214 14.0126V23.0117H73.6404ZM69.6871 16.1194C68.1859 16.3301 67.125 16.6612 66.5145 17.1227C65.904 17.5842 65.6038 18.3366 65.6038 19.39C65.6038 20.2126 65.884 20.8848 66.4545 21.4165C67.025 21.9482 67.8056 22.2091 68.8164 22.2091C70.1976 22.2091 71.3485 21.7175 72.2693 20.7243C73.19 19.7411 73.6404 18.467 73.6404 16.902V15.5476L69.6871 16.1194Z"
      fill="black"
    />
    <path
      d="M78.8948 22.316V20.7911C80.336 21.6739 81.7972 22.1154 83.2684 22.1154C84.8397 22.1154 86.0306 21.8044 86.8413 21.1723C87.652 20.5503 88.0623 19.6675 88.0623 18.5438C88.0623 17.5506 87.7921 16.7581 87.2416 16.1762C86.6912 15.5843 85.5102 14.7817 83.6887 13.7684C81.647 12.6248 80.356 11.6717 79.8155 10.8992C79.2751 10.1267 79.0049 9.24385 79.0049 8.23058C79.0049 6.85614 79.5553 5.70242 80.6562 4.73932C81.7571 3.78624 83.2283 3.30469 85.0799 3.30469C86.2808 3.30469 87.4718 3.4953 88.6728 3.88656V5.2911C87.4918 4.77945 86.2408 4.5186 84.8997 4.5186C83.5386 4.5186 82.4477 4.84967 81.657 5.52184C80.8564 6.18398 80.4561 7.03673 80.4561 8.06003C80.4561 9.05323 80.7263 9.83576 81.2767 10.4277C81.8272 11.0095 83.0082 11.8121 84.8196 12.8154C86.7012 13.8387 87.9522 14.7516 88.5727 15.5542C89.1932 16.3468 89.5035 17.2697 89.5035 18.3031C89.5035 19.7879 88.973 21.0018 87.9022 21.9348C86.8313 22.8678 85.3201 23.3393 83.3684 23.3393C82.6779 23.3393 81.8772 23.239 80.9765 23.0283C80.0657 22.8277 79.3752 22.5869 78.8948 22.316Z"
      fill="black"
    />
    <path
      d="M99.2914 23.355C97.1997 23.355 95.5383 22.6928 94.3173 21.3585C93.0963 20.0343 92.4858 18.2987 92.4858 16.1718C92.4858 13.8744 93.1264 12.0786 94.3974 10.7744C95.6785 9.48023 97.3498 8.82812 99.4415 8.82812C101.493 8.82812 103.105 9.4702 104.296 10.7644C105.487 12.0485 106.077 13.8343 106.077 16.1116C106.077 18.2686 105.466 20.0042 104.256 21.3385C103.055 22.6828 101.403 23.355 99.2914 23.355ZM99.3915 9.97181C97.7001 9.97181 96.349 10.5236 95.3482 11.6171C94.3474 12.7207 93.8369 14.2456 93.8369 16.2019C93.8369 18.0178 94.3274 19.4725 95.3082 20.566C96.289 21.6695 97.6301 22.2113 99.3315 22.2113C101.063 22.2113 102.394 21.6695 103.325 20.5861C104.256 19.5026 104.726 18.0077 104.726 16.0815C104.726 14.1051 104.266 12.5903 103.335 11.5369C102.414 10.4935 101.093 9.97181 99.3915 9.97181Z"
      fill="black"
    />
    <path
      d="M115.165 3.82961C114.814 3.56876 114.294 3.43834 113.593 3.43834C111.822 3.43834 110.941 4.58203 110.941 6.86941V9.16682H114.744V10.3005H110.941V23.0014H109.65V10.3005H107.068V9.16682H109.65V6.75906C109.65 5.30436 110.03 4.19077 110.791 3.43834C111.552 2.68592 112.502 2.30469 113.643 2.30469C114.254 2.30469 114.754 2.39498 115.155 2.56553V3.82961H115.165Z"
      fill="black"
    />
    <path
      d="M123.251 22.8232C122.581 23.1342 121.95 23.2847 121.36 23.2847C119.238 23.2847 118.177 22.0608 118.177 19.6129V10.3129H115.595V9.17923H118.177V5.57761C118.387 5.50738 118.597 5.43716 118.818 5.3569C119.038 5.2967 119.258 5.22648 119.458 5.15625V9.16919H123.241V10.3029H119.458V19.4423C119.458 20.3954 119.618 21.0876 119.928 21.519C120.239 21.9504 120.769 22.1611 121.52 22.1611C122.05 22.1611 122.631 21.9906 123.241 21.6595V22.8232H123.251Z"
      fill="black"
    />
    <path d="M23.139 1.83594H0V24.1278H23.139V1.83594Z" fill="#FCC013" />
    <path
      d="M15.9229 10.5359C16.3132 10.5359 16.6435 10.4055 16.9137 10.1547C17.1839 9.90384 17.324 9.60287 17.324 9.24171C17.324 8.87051 17.1839 8.55951 16.9137 8.3087C16.6435 8.05789 16.3132 7.9375 15.9229 7.9375C15.5426 7.9375 15.2223 8.05789 14.9521 8.3087C14.6819 8.55951 14.5518 8.87051 14.5518 9.24171C14.5518 9.6129 14.6819 9.92391 14.9521 10.1647C15.2223 10.4155 15.5426 10.5359 15.9229 10.5359Z"
      fill="white"
    />
    <path d="M16.9538 13.7031H14.832V24.1267H16.9538V13.7031Z" fill="white" />
  </svg>
);

//Gray social media Icons

const GrayLinkdin = ({ size = 20 }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.125" width="24" height="24" rx="4" fill="#B4B9C9" />
    <path
      d="M6 7.74162C6 7.35682 6.13514 7.03936 6.40541 6.78924C6.67567 6.53912 7.02703 6.41406 7.45946 6.41406C7.88417 6.41406 8.2278 6.53719 8.49035 6.78347C8.76061 7.03744 8.89575 7.36836 8.89575 7.77626C8.89575 8.14566 8.76448 8.4535 8.50193 8.69978C8.23166 8.95374 7.87645 9.08073 7.43629 9.08073H7.42471C7 9.08073 6.65637 8.95374 6.39382 8.69978C6.13127 8.44581 6 8.12642 6 7.74162ZM6.15058 17.8426V10.1312H8.72201V17.8426H6.15058ZM10.1467 17.8426H12.7181V13.5367C12.7181 13.2673 12.749 13.0596 12.8108 12.9133C12.9189 12.6517 13.083 12.4304 13.3031 12.2496C13.5232 12.0687 13.7992 11.9783 14.1313 11.9783C14.9961 11.9783 15.4286 12.5593 15.4286 13.7214V17.8426H18V13.4213C18 12.2823 17.7297 11.4184 17.1892 10.8296C16.6486 10.2409 15.9344 9.94653 15.0463 9.94653C14.0502 9.94653 13.2741 10.3737 12.7181 11.2279V11.251H12.7066L12.7181 11.2279V10.1312H10.1467C10.1622 10.3775 10.1699 11.1432 10.1699 12.4285C10.1699 13.7137 10.1622 15.5184 10.1467 17.8426Z"
      fill="white"
    />
  </svg>
);

const GrayFaceBook = ({ size = 20 }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.125" width="24" height="24" rx="4" fill="#B4B9C9" />
    <path
      d="M12.9759 18.125V12.6512H14.9057L15.1947 10.518H12.9759V9.15602C12.9759 8.5384 13.156 8.11752 14.0863 8.11752L15.2728 8.11699V6.20905C15.0675 6.1831 14.3632 6.125 13.5439 6.125C11.8332 6.125 10.662 7.11912 10.662 8.94482V10.518H8.72729V12.6512H10.662V18.1249H12.9759V18.125Z"
      fill="white"
    />
  </svg>
);

const GrayInsta = ({ size = 20 }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.125" width="24" height="24" rx="4" fill="#B4B9C9" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 12.125C6 9.72824 6 8.52986 6.57113 7.6667C6.82616 7.28126 7.15626 6.95116 7.5417 6.69613C8.40486 6.125 9.60324 6.125 12 6.125C14.3968 6.125 15.5951 6.125 16.4583 6.69613C16.8437 6.95116 17.1738 7.28126 17.4289 7.6667C18 8.52986 18 9.72824 18 12.125C18 14.5218 18 15.7201 17.4289 16.5833C17.1738 16.9687 16.8437 17.2988 16.4583 17.5539C15.5951 18.125 14.3968 18.125 12 18.125C9.60324 18.125 8.40486 18.125 7.5417 17.5539C7.15626 17.2988 6.82616 16.9687 6.57113 16.5833C6 15.7201 6 14.5218 6 12.125ZM15.1062 12.1252C15.1062 13.8407 13.7154 15.2314 11.9999 15.2314C10.2843 15.2314 8.89362 13.8407 8.89362 12.1252C8.89362 10.4096 10.2843 9.01888 11.9999 9.01888C13.7154 9.01888 15.1062 10.4096 15.1062 12.1252ZM11.9999 14.1805C13.135 14.1805 14.0552 13.2603 14.0552 12.1252C14.0552 10.99 13.135 10.0698 11.9999 10.0698C10.8648 10.0698 9.94456 10.99 9.94456 12.1252C9.94456 13.2603 10.8648 14.1805 11.9999 14.1805ZM15.2288 9.5927C15.632 9.5927 15.9587 9.26592 15.9587 8.86282C15.9587 8.45971 15.632 8.13293 15.2288 8.13293C14.8257 8.13293 14.499 8.45971 14.499 8.86282C14.499 9.26592 14.8257 9.5927 15.2288 9.5927Z"
      fill="white"
    />
  </svg>
);

const GrayTwitter = ({ size = 20 }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.125" width="24" height="24" rx="4" fill="#B4B9C9" />
    <path
      d="M13.382 11.5388L17.8257 5.76074H16.5386L12.7386 10.7021L8.93824 5.76074H4.69531L10.6169 13.4606L6.17281 19.239H7.45994L11.2603 14.2973L15.0611 19.239H19.304L13.382 11.5388ZM6.78766 6.80813H8.45632L17.2113 18.1915H15.5426L6.78766 6.80813Z"
      fill="white"
    />
  </svg>
);

const GrayYoutube = ({ size = 20 }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.125" width="24" height="24" rx="4" fill="#B4B9C9" />
    <path
      d="M12.2925 16.7797L9.58433 16.7291C8.7075 16.7115 7.82849 16.7467 6.96885 16.564C5.66114 16.291 5.5685 14.9524 5.47156 13.8295C5.33798 12.251 5.38969 10.6438 5.64176 9.07839C5.78407 8.20004 6.34409 7.67593 7.21014 7.6189C10.1337 7.41191 13.0767 7.43644 15.9938 7.53301C16.3019 7.54186 16.6121 7.59024 16.9159 7.64532C18.4154 7.91393 18.452 9.43086 18.5492 10.7078C18.6461 11.998 18.6052 13.2948 18.4199 14.5761C18.2713 15.637 17.9868 16.5267 16.7866 16.6126C15.2828 16.7249 13.8134 16.8154 12.3054 16.7866C12.3054 16.7797 12.2968 16.7797 12.2925 16.7797ZM10.7003 14.0938C11.8336 13.4288 12.9453 12.775 14.072 12.1145C12.9367 11.4495 11.8271 10.7957 10.7003 10.1352V14.0938Z"
      fill="white"
    />
  </svg>
);

const SvgIconsSet = {
  LogoOrginal,
  SideArrow,
  RightArrow,
  DownArrow,
  UpArrow,
  Linkedin,
  GrayLinkdin,
  GrayFaceBook,
  GrayInsta,
  GrayTwitter,
  GrayYoutube,
};

export default SvgIconsSet;
