import React from "react";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../scss/style.scss";
import { FaArrowAltCircleUp } from "react-icons/fa";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

const Layout = (props) => {
  React.useEffect(() => {
    //let elementId = document.getElementById("up-icon");
    let headerID = document.getElementById("header");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
       // elementId.classList.add("show");
       // elementId.classList.remove("hidden");
        headerID.classList.add("sticky");
      } else {
       // elementId.classList.remove("show");
        //elementId.classList.add("hidden");
        headerID.classList.remove("sticky");
      }
    });
    // window.scrollTo(0, 0);
  });

  return (
    <React.Fragment>

      <Helmet>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TLBJ92J2');
          `}
        </script>
      </Helmet>

      <Seo />
      <div className={`page${props.bodyClass ? ` ${props.bodyClass}` : ""}`}>
        <div id="wrapper" className="wrapper">
          <Header />
          {props.children}
          <div className="footer-main">
            <Container>
              <Footer />
            </Container>
          </div>
        </div>

        {/* <span
          className="up-icon"
          id="up-icon"
          onClick={() => window.scrollTo(0, 0)}
          aria-label="scroll"
        >
          <FaArrowAltCircleUp />
        </span> */}
      </div>
    </React.Fragment>
  );
};

export default Layout;
